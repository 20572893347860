<template>
  <v-container fluid>
    <v-row>
      <v-col
        class="d-flex align-center justify-center flex-column"
        style="height: 100vh; width: 100%"
        :style="{ background: background }"
      >
        <v-card-title class="d-flex flex-column my-4">
          <h1>CMS</h1>
        </v-card-title>

        <v-card
          :loading="$store.state.loading.show"
          width="400"
          shaped
          class="pb-15"
          elevation="1"
        >
          <v-card-title class="d-flex flex-column">
            <span class="mt-5"> تسجيل الدخول </span>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="submit()"
            >
              <v-text-field
                v-model="form.email"
                :rules="[$rules.required()]"
                label="البريد الالكتروني او اسم المستخدم"
                outlined
                required
              >
              </v-text-field>
              <v-text-field
                v-model="form.password"
                :rules="[$rules.required()]"
                label="كلمة المرور"
                outlined
                required
                type="password"
              >
              </v-text-field>
              <h3 v-if="error" class="error--text ml-2 mb-5">
                غير مخول بالدخول
              </h3>
              <v-btn :disabled="!valid" block color="primary" type="submit">
                تسجيل الدخول
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        class="col"
        md="0"
        style="
          background-image: url(https://his.muraba.iq/assets/side-bg.fc5d3d43.svg);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          height: 100vh;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <div class="white--text d-flex flex-column align-end" style="gap: 5px">
          <div class="text-h3 text-md-h2 text-lg-h1">مرحبا بعودتك,</div>
          <div class="text-subtitle-2 text-md-subtitle-1 text-lg-h6">
            يرجى تسجيل الدخول لاستعمال الموقع بصورة كاملة
          </div>
        </div>
        <v-btn
          class="myButton"
          small
          href="https://iraqimuraba.com/"
          target="_blank"
          elevation="0"
        >
          Al-muraba.iq
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {
  authLogin,
  commitUserData,
  loadAndSaveCurrentUserInfo,
} from '@/libs/auth/authHelper';
import { mapGetters } from 'vuex';
import { loadCMSInformation } from '@/config/resource';
import { saveAccessToken, saveCMSInformation } from '@/helper/localStorage';
import { url } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      valid: false,
      error: false,
    };
  },
  mounted() {
    this.valid = false;
  },
  methods: {
    submit() {
      this.$refs.form.validate();
      if (!this.valid) return;
      this.login();
    },
    login() {
      authLogin({
        usernameOrEmail: this.form.email,
        password: this.form.password,
        onStart: () => {
          this.commitLoading(true);
        },
        onLogin: async (accessToken) => {
          saveAccessToken(accessToken);
          this.commitAccessToken(accessToken);
          const currentUser = await loadAndSaveCurrentUserInfo(accessToken);
          if (!currentUser.isAuthenticated) return this.notAuthHanlder();
          await this.authHanlder();
        },
        onFail: (error) => {
          this.notAuthHanlder();
        },
        onFinsh: () => {
          this.commitLoading(false);
        },
      });
    },
    async authHanlder() {
      this.error = false;
      await loadCMSInformation(saveCMSInformation);
      commitUserData();
      this.goToNextPage();
    },
    notAuthHanlder() {
      this.error = true;
    },
    goToNextPage() {
      this.$router.replace('/');
    },
    commitAccessToken(accessToken) {
      this.$store.commit('SET_ACCESS_TOKEN', accessToken);
    },
    commitLoading(status) {
      this.$store.commit('loading/SET_SHOW', !!status);
    },
  },
  computed: {
    ...mapGetters('style', ['background']),
  },
};
</script>

<style scoped>
.col {
  position: relative;
}

.myButton {
  border-radius: 20px 0 0 0;
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
