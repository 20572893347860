import { saveCMSInformation } from '@/helper/localStorage';
import axiosIns from '@/libs/http/http';

export const loadCMSInformation = async () => {
  return axiosIns
    .get('app/information', {})
    .then((response) => {
      const { uploadDirectory } = response.data;
      saveCMSInformation({ uploadDirectory });
    })
    .catch((error) => {})
    .finally(() => {});
};
